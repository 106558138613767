html {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.strong {
  font-weight: bold!important;
}
body {
  background: #fff;
  overflow-y: scroll;
  position: relative;
  min-height: 100%;
}
.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
}
/* TOP NAVIGATION */
#main-header {
  background: rgba(51, 51, 51, 0.72);
}
#main-header #main-navigation {
  /* main navigaton */
  color: #FFF;
  border: none;
  border-radius: 0;
  background: transparent;
  margin-bottom: 0;
}
#main-header #main-navigation .navbar-header {
  margin-right: 100px;
}
#main-header #main-navigation .navbar-brand {
  height: auto;
  padding: 9px 30px;
  background-color: #fff;
  margin-top: 0;
  margin-bottom: -12px;
}
#main-header #main-navigation .navbar-right .dropdown-menu {
  background: rgba(51, 51, 51, 0.72);
  margin-top: 5px;
  right: 0;
}
#main-header #main-navigation .navbar-right .dropdown-menu li {
  color: #fff;
}
#main-header #main-navigation .navbar-right .dropdown-menu li > a:hover {
  color: #fff;
}
#main-header #main-navigation .navbar-right .dropdown-menu lia:hover,
#main-header #main-navigation .navbar-right .dropdown-menu lia:focus {
  background-color: #ff9900;
}
#main-header #main-navigation .navbar-right .dropdown-menu li#search-btn a {
  background-color: #333;
}
#main-header #main-navigation .navbar-nav {
  height: auto;
  padding: 0;
  float: none;
  margin: 0 auto;
  text-align: center;
  border-radius: 0;
}
#main-header #main-navigation .navbar-nav li {
  position: static;
}
#main-header #main-navigation .navbar-nav li a {
  color: #fff;
}
#main-header #main-navigation .navbar-nav > li > a:focus,
#main-header #main-navigation .navbar-nav > li > a:hover {
  color: #fff;
  background-color: #ff9900;
}
#main-header #main-navigation .navbar-nav li.active > a,
#main-header #main-navigation .navbar-nav li.open > a {
  background: transparent;
  box-shadow: inset 0 -5px 0 #ff9900;
}
#main-header #main-navigation .navbar-nav li.active > a:hover,
#main-header #main-navigation .navbar-nav li.open > a:hover,
#main-header #main-navigation .navbar-nav li.active > a:focus,
#main-header #main-navigation .navbar-nav li.open > a:focus {
  background: #ff9900;
}
#main-header #main-navigation .navbar-nav li.open:after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #ff9900 transparent transparent transparent;
  position: absolute;
  margin-left: -5px;
}
#main-header #main-navigation #categories-dropdown {
  color: #fff;
  background: rgba(51, 51, 51, 0.9);
  border: 0;
  border-radius: 0;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
}
#main-header #main-navigation #categories-dropdown .cell {
  float: left;
  width: 220px;
}
#main-header #main-navigation #categories-dropdown .cell ul {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
#main-header #main-navigation #categories-dropdown .cell ul li {
  display: block;
  text-align: left;
  margin-left: 20px;
  font-size: 12px;
}
#main-header #main-navigation #categories-dropdown .cell ul li > a {
  color: #fff;
  font-weight: normal;
  font-family: "Open sans", sans-serif;
  font-size: 12px;
  display: block;
}
#main-header #main-navigation #categories-dropdown .cell ul .title {
  color: #ff9900;
  font-weight: bold;
  font-family: "Open sans", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 20px;
}
#main-header #main-navigation #search-btn .open {
  background: #333;
}
#main-header #main-navigation #search-btn .open:after {
  content: " ";
  display: none;
}
#main-header #main-navigation #seach-dropdown {
  width: 280px;
  background: transparent;
  border: 0 solid red;
  font-size: 14px;
  font-style: italic;
  height: auto;
  margin: 0;
  padding: 0;
  outline: 0;
  margin-top: 5px;
}
#main-header #main-navigation #seach-dropdown input {
  margin: 0;
  background: rgba(255, 255, 255, 0.72);
  color: #333;
  display: block;
  padding: 7px;
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: none;
}
#main-header #main-navigation #seach-dropdown a {
  position: absolute;
  background: transparent !important;
  border: 0;
  color: #333;
  margin-top: 7px;
  margin-right: 7px;
  top: 0;
  right: 0;
  z-index: 2;
}
#main-header #fixed-nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: rgba(51, 51, 51, 0.8);
}
#main-header .slider {
  /* slider */
}
#main-header .slider .item img {
  width: 100%;
}
#main-header .slider .item .carousel-caption {
  text-align: left;
  left: 19%;
  padding-bottom: 60px;
  z-index: 0;
}
#main-header .slider .item .carousel-caption h4 {
  background: rgba(255, 153, 0, 0.58);
  /*#ff9900*/
  padding: 12px 20px;
  float: left;
  font-size: 48px;
  color: #fff;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  text-shadow: 1px 1px 1px #000;
}
#main-header .slider .item .carousel-caption p {
  background: rgba(255, 255, 255, 0.74);
  padding: 12px 20px;
  float: left;
  font-size: 20px;
  color: #000;
  text-shadow: none;
  text-transform: uppercase;
  font-style: italic;
  margin-top: 10px;
}
#main-header .slider .carousel-control {
  background: transparent;
  color: #fff;
  top: 50%;
  bottom: 25%;
  width: 10%;
  z-index: 10;
}
#main-header .slider .carousel-control i {
  font-size: 40px;
}
#main-header .slider .carousel-control .left {
  left: 100%;
}
#main-header .slider .carousel-control .right {
  right: 100%;
}
a,
a:hover {
  color: inherit;
}
/* BODY -> content */
.overflow {
  overflow: hidden !important;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #ff9900;
}
#body {
  margin-top: -50px;
  background: transparent;
  z-index: 20;
  min-height: 700px;
  position: relative;
}
#body .main-text-color {
  color: #ff9900;
}
#body #contacts_map {
  height: 500px;
  width: 100%;
  margin-top: 50px;
  z-index: -1;
}
#body .contacts-layout #offseted-title {
  margin-top: -110px;
}
#body .contacts-layout #offseted-title h2 {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
}
#body .contacts-layout .contacts-form {
  overflow: hidden;
  margin: 20px 0;
  margin-bottom: 40px;
}
#body .contacts-layout .contacts-form .form-control {
  border-radius: 0;
  margin-bottom: 15px;
}
#body .contacts-layout .contacts-form textarea {
  height: 185px;
  margin-bottom: 10px;
}
#body .contacts-layout .contacts-form button {
  float: right;
  border-radius: 0;
  background: #ff9900;
  color: #fff;
  border: 1px solid #b36b00;
}
#body .contacts-layout .contacts-form button:hover {
  transition: 0.3s;
  background: #cc7a00;
  border: 1px solid #cc7a00;
}
#body .contacts-layout .address-cols {
  overflow: hidden;
  color: #999;
  transition: 1s;
  height: 220px;
  background: #fff;
  padding: 0 10px;
}
#body .contacts-layout .address-cols h3 {
  margin: 20px 0;
  color: #999;
  font-size: 20px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}
#body .contacts-layout .address-cols h3 i {
  color: #ff9900;
}
#body .contacts-layout .address-cols p {
  margin: 0;
  padding: 0;
}
#body .grayed-bg-area {
  background: #eee;
  margin-top: 110px;
}
#body .grayed-bg-area .carousel .item {
  overflow: hidden;
}
#body .grayed-bg-area .carousel .item .active {
  left: 10px;
}
#body .grayed-bg-area .carousel .item .cell {
  float: left;
  width: 190px;
  margin: 25px 0;
  margin-left: 35px;
}
#body .grayed-bg-area .carousel .item .cell figure {
  width: 100%;
}
#body .grayed-bg-area .carousel .item .cell figure img {
  width: 100%;
}
#body .grayed-bg-area .carousel .item .cell .title {
  color: #333;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}
#body .grayed-bg-area .carousel .carousel-control {
  background: transparent;
  width: auto;
  color: #ff9900;
  top: 50%;
  margin-top: -20px;
  opacity: 1;
  cursor: pointer;
  text-shadow: none;
}
#body .grayed-bg-area .carousel .carousel-control:hover {
  opacity: 1;
}
#body .grayed-bg-area .title-inside {
  border-bottom: 1px solid #ccc;
  color: #ff9900;
  font-size: 20px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 20px;
}
#body .grayed-bg-area .outside-title {
  background: #FFF;
  color: #ff9900;
  text-transform: uppercase;
  text-align: left;
  font-size: 18px;
}
#body .grayed-bg-area .shadow-top:before {
  content: " ";
  background: transparent url("../img/grayed-bg-area-top-shadow.png") center top no-repeat;
  display: block;
  height: 50px;
  width: 100%;
  left: 0;
  position: absolute;
}
#body .grayed-bg-area .shadow-bottom:after {
  content: " ";
  background: transparent url("../img/grayed-bg-area-bottom-shadow.png") center bottom no-repeat;
  display: block;
  height: 30px;
  width: 100%;
  left: 0;
  margin-top: -30px;
  position: absolute;
}
#body .grayed-bg-area .title .text {
  background: #FFF;
  color: #666;
  float: left;
  padding: 10px 15px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  z-index: 2;
  position: relative;
}
#body .grayed-bg-area .title .text h3 {
  font-weight: bold;
  font-size: 24px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
#body .grayed-bg-area .title .text p {
  padding: 0;
  margin: 0;
  font-size: 12px;
}
#body .grayed-bg-area .services-col {
  margin-top: 30px;
}
#body .grayed-bg-area .services-col:hover figure svg {
  fill: #ff9900;
  transition: 0.3s;
  border: 5px solid #ff9900;
}
#body .grayed-bg-area .services-col figure {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  height: 128px;
  display: block;
}
#body .grayed-bg-area .services-col figure svg {
  fill: #666;
  background: transparent ;
  width: 120px;
  height: 120px;
  padding: 20px;
  border: 5px solid #fff;
  border-radius: 50%;
  box-shadow: 2px 2px 5px #ccc;
}
#body .grayed-bg-area .services-col p {
  font-size: 16px;
  color: #666;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin: 0;
  padding: 16px;
}
#body .grayed-bg-area .btn-gray-flat {
  background: #666;
  color: #fff;
  text-shadow: 1px 1px 1px #666;
  padding: 5px 10px;
  border-radius: 0;
  margin-top: 50px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 35px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
#body .grayed-bg-area .btn-gray-flat:hover {
  background: #5A5A5A;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}
#body .brands-row {
  padding: 30px 0;
  overflow: hidden;
}
#body .brands-row ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
#body .brands-row ul li {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: block;
  float: left;
  width: 135px;
  text-align: center;
  margin: 10px  0;
}
#body .brands-row ul li:hover .monocromed {
  display: none;
}
#body .brands-row ul li:hover .colored {
  display: block;
}
#body .brands-row ul li a {
  overflow: hidden;
  display: block;
  height: 58px;
  vertical-align: middle;
  margin: 0 5px;
}
#body .brands-row ul li a .monocromed {
  display: block;
}
#body .brands-row ul li a .colored {
  display: none;
}
#body .brands-row ul li a {
  text-decoration: none;
}
#body .brands-row ul li a img {
  outline: none;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  float: none;
}
#body .products-layout .side-categories {
  list-style: none;
  margin: 0;
  padding: 0;
}
#body .products-layout .side-categories li {
  display: block;
  padding: 3px;
}
#body .products-layout .side-categories li a {
  display: block;
  color: #ff9900;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
#body .products-layout .page-header {
  background: #f6f6f6;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f6f6f6 0%, #eaeaea 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#eaeaea', GradientType=0);
  /* IE6-9 */
  overflow: hidden;
  margin-top: 105px;
  padding: 21px 0;
}
#body .products-layout .page-header .breadcrumb {
  background: transparent;
  color: #666;
  margin: 0;
  padding: 10px;
  font-size: 0.938em;
}
#body .products-layout .page-header .breadcrumb > li + li:before {
  content: "/";
  color: #666 ;
  font-size: 12px;
}
#body .products-layout .page-header .breadcrumb a {
  color: #666;
}
#body .products-layout .page-header:before {
  content: " ";
  background: transparent url("../img/grayed-bg-area-top-shadow.png") center top no-repeat;
  display: block;
  height: 25px;
  width: 100%;
  position: absolute;
  top: 0;
  margin-top: 82px;
  overflow: hidden;
}
#body .products-layout .page-header h3 {
  color: #ff9900;
  font-size: 38px;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-transform: uppercase;
}
#body .products-layout .view-layout h1 {
  margin: 0;
  padding: 0;
  color: #ff9900;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
}
#body .products-layout .view-layout .main-image figure {
  margin: 0 auto;
  padding: 0;
  border: 1px solid #ccc;
  text-align: center;
  margin-bottom: 20px;
  max-width: 100%;
  /*
					img
					{
						width:100%;
					}
				  */
}
#body .products-layout .view-layout .info {
  padding-left: 20px;
}
#body .products-layout .view-layout .info h1 {
  margin: 0;
  padding: 0;
  color: #ff9900;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
}
#body .products-layout .view-layout .info p {
  padding: 10px 0;
  margin-bottom: 15px;
}
#body .products-layout .view-layout .info .desc-info {
  margin-top: 10px;
}
#body .products-layout .view-layout .info .desc-info .title {
  background: #ebebeb;
  font-size: 1em;
  color: #999;
  padding: 10px;
  text-align: left;
}
#body .products-layout .view-layout .info .desc-info img {
  display: block;
  margin: 20px auto;
  max-width: 100%;
}
#body .products-layout .view-layout .info .desc-info table {
  max-width: 100%;
  font-size: 0.8em;
}
#body .products-layout .view-layout .info .desc-info table thead th {
  color: #666;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #ff9900;
}
#body .products-layout .view-layout .info .desc-info table tbody td {
  text-align: center;
  vertical-align: middle;
}
#body .products-layout .products-row .item {
  position: relative;
  border: 1px solid #ccc;
  margin-bottom: 25px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 220px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
#body .products-layout .products-row .item h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-style: italic;
  text-align: center;
  color: #666;
  padding: 0 10px;
}
#body .products-layout .products-row .item figure img {
  width: 100%;
}
#body .products-layout .products-row .item .overlay {
  background: rgba(0, 0, 0, 0.68);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  cursor: pointer;
}
#body .products-layout .products-row .item .overlay .info {
  display: block;
  /*height:50px;*/
  background: #666;
  color: #fff;
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 10px;
  z-index: 999;
}
#body .products-layout .products-row .item .overlay .info .search-icon {
  color: #ff9900;
  padding: 10px;
  border: 10px solid #ff9900;
  text-align: center;
  float: left;
  margin-left: 20px;
}
#body .products-layout .products-row .item .overlay .info h4 {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: left;
  color: #fff;
}
#body .products-layout .products-row .item .overlay .info p {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  margin: 0;
}
#body .products-layout .products-row .item .overlay .info .btn {
  background: #333;
  text-align: center;
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  font-size: 37px;
  padding: 0 15px;
  line-height: 45px;
}
#body .products-layout .products-row .item:hover .overlay {
  display: block;
}
#body .products-layout .products-row .item:hover .overlay .search-icon {
  margin: auto auto;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -60px;
  margin-left: -32px;
}
#body .products-layout .products-row .item:hover .overlay .search-icon svg {
  width: 64px;
  height: 64px;
  fill: #ff9900;
}
#body .products-layout .products-row .item .orange-bage {
  background: #ff9900;
  color: #FFF;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  padding: 7px 10px;
}
#body .products-layout.product .page-header:before {
  margin-top: 184px;
}
#body .side-categories a[data-toggle="collapse"]:focus,
#body .side-categories a[data-toggle="collapse"]:hover,
#body .side-categories a[data-toggle="collapse"]:active {
  text-decoration: none;
  color: #b36b00;
}
#body .side-categories a[data-toggle="collapse"][aria-expanded="false"]:after {
  content: "\f067";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: inherit;
  font-size: inherit;
  float: right;
}
#body .side-categories a[data-toggle="collapse"][aria-expanded="true"]:after {
  content: "\f068";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: inherit;
  font-size: inherit;
  float: right;
}
#body .side-categories ul.collapse,
#body .side-categories ul.collapsing {
  padding-left: 10px;
}
#body .page-layout {
  margin-top: 150px;
  margin-bottom: 80px;
}
#index-tabs .white-bg {
  background: #fff !important;
}
#index-tabs .nav-tabs {
  margin-left: 30px;
}
#index-tabs .nav-tabs a {
  color: #fff;
  background-color: #333;
  border: 0;
  cursor: pointer;
  border-radius: 0;
  padding: 8px 10px;
  font-size: 14px;
  text-transform: uppercase;
}
#index-tabs .nav-tabs .active a {
  color: #fff;
  background-color: #ff9900;
  border: 0;
  cursor: pointer;
  border-radius: 0;
  padding: 8px 10px;
  font-size: 14px;
}
#index-tabs .nav-tabs .active:after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #ff9900 transparent transparent transparent;
  position: absolute;
  left: 50%;
  margin-left: -5px;
}
#index-tabs .tab-content {
  background: #FFf;
}
#index-tabs .futured-products {
  list-style: none;
  margin: 0;
  padding: 25px 0;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
#index-tabs .futured-products li {
  float: left;
  display: block;
  position: relative;
  margin-left: 25px;
}
#index-tabs .futured-products li .item figure {
  border: 1px solid #ccc;
}
#index-tabs .futured-products li .item .overlay {
  background: rgba(0, 0, 0, 0.68);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  cursor: pointer;
}
#index-tabs .futured-products li .item .overlay .info {
  display: block;
  /*height:60px;*/
  background: #666;
  color: #fff;
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 15px;
}
#index-tabs .futured-products li .item .overlay .info .search-icon {
  color: #ff9900;
  padding: 10px;
  border: 10px solid #ff9900;
  text-align: center;
  float: left;
  margin-left: 20px;
}
#index-tabs .futured-products li .item .overlay .info h4 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
#index-tabs .futured-products li .item .overlay .info p {
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  margin: 0;
}
#index-tabs .futured-products li .item .overlay .info .btn {
  background: #333;
  text-align: center;
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  font-size: 46px;
  padding: 0 15px;
}
#index-tabs .futured-products li .item:hover .overlay {
  display: block;
}
#index-tabs .futured-products li .item:hover .overlay a.search-icon {
  margin-left: 95px;
  margin-top: 70px;
  float: left;
}
#index-tabs .futured-products li .item:hover .overlay a.search-icon svg {
  width: 64px;
  height: 64px;
  fill: #ff9900;
}
#index-tabs .futured-products li .item .orange-bage {
  background: #ff9900;
  color: #FFF;
  font-family: "Open Sans", sans-serif;
  position: absolute;
  padding: 7px 10px;
}
#main-footer {
  background: #666;
  color: #ccc;
  padding: 20px 0;
  padding-bottom: 0;
  position: relative;
}
#main-footer .bottom-darkgray {
  color: #fff;
  background: #333;
  margin-top: 25px;
}
#main-footer .first-col {
  width: 415px;
}
#main-footer .first-col:before {
  content: " ";
  display: block;
  width: 0;
  border-right: 1px solid #ccc;
  height: 120px;
  float: right;
  margin-top: 25px;
}
#main-footer .first-col p {
  padding: 0;
  margin: 0;
  margin-top: 20px;
}
#main-footer .middle-col {
  width: 450px;
}
#main-footer .middle-col:before {
  content: " ";
  display: block;
  width: 0;
  border-right: 1px solid #ccc;
  height: 120px;
  float: right;
  margin-top: 25px;
}
#main-footer .middle-col ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#main-footer .middle-col ul li {
  display: block;
  padding: 10px;
}
#main-footer .middle-col ul li:hover {
  color: #fff;
  transition: 0.3s;
}
#main-footer .middle-col ul li i {
  color: #ff9900;
  float: left;
  margin: 10px;
  margin-right: 20px;
}
#main-footer .last-col {
  width: 270px;
}
#main-footer .last-col ul {
  margin: 0;
  padding: 0;
  margin-left: 50px;
  list-style: none;
  margin-top: 25px;
}
#main-footer .last-col ul li {
  display: block;
  padding: 3px;
}
#main-footer .last-col ul li a {
  display: block;
  color: #fff;
  font-size: 12px;
}
#main-footer .last-col ul li a:hover {
  color: #ff9900;
  text-decoration: none;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
  #main-header #main-navigation .navbar-header {
    margin-right: 180px;
  }
  .products-layout .page-header .breadcrumb {
    background: transparent;
    color: #666;
    margin: 0;
    padding: 9px 0px;
    font-size: 15px;
    transition: 1s;
  }
  .products-layout .page-header .breadcrumb > li + li:before {
    content: "/";
    color: #666 ;
    font-size: 13px;
  }
  .products-layout .page-header .breadcrumb a {
    color: #666;
  }
  #main-footer {
    transition: 1s;
    text-align: center;
  }
  #main-footer #footer-logo {
    text-align: left;
    transition: 1s;
  }
  #main-footer .first-col {
    text-align: left;
    transition: 1s;
  }
  #main-footer .middle-col ul {
    overflow: hidden;
    transition: 1s;
  }
  #main-footer .middle-col ul li {
    float: left;
    text-align: left;
    vertical-align: middle;
    overflow: hidden;
    transition: 1s;
  }
  #main-footer .middle-col ul li div:first-child {
    color: #ff9900;
    float: left;
    margin: 0px 10px;
    display: block;
    text-align: left;
    overflow: hidden;
    transition: 1s;
  }
  #main-footer .middle-col ul li div:first-child i {
    float: none;
    display: inline;
    margin: 0;
    height: 0;
    font-size: 1.5em;
    transition: 1s;
  }
  #main-footer .middle-col ul li div:last-child {
    color: #fff;
    text-align: left;
    width: 290px;
    transition: 1s;
    float: left;
  }
  #main-footer .last-col:before {
    display: none;
    transition: 1s;
  }
  #main-footer .last-col ul li {
    text-align: left;
    transition: 1s;
  }
}
/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  #main-header .container > .navbar-header,
  #main-header .container-fluid > .navbar-header,
  #main-header .container > .navbar-collapse,
  #main-header .container-fluid > .navbar-collapse {
    margin-right: -100px;
    transition: 1s;
  }
  #main-header .slider .carousel-control {
    color: #fff;
    top: 0%;
    bottom: 0%;
    width: 15%;
    z-index: 10;
    line-height: 20em;
  }
  #body {
    margin: 0 auto;
  }
  #body #index-tabs .nav-tabs {
    margin: 20px auto;
    border: 0px;
    text-align: center;
  }
  #body #index-tabs .tab-content > .active {
    height: 320px;
    overflow: hidden;
    overflow-x: scroll;
  }
  #body #index-tabs .tab-content > .active .futured-products {
    min-width: 1100px;
    padding: 0;
    margin: 0;
  }
  #body #index-tabs .tab-content > .active .futured-products li {
    margin: 0 10px;
    padding: 0;
    width: auto !important;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay {
    background: transparent;
    position: relative;
    display: block;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .search-icon,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .search-icon {
    display: none;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info {
    position: relative;
    background: transparent;
    color: #666;
    text-align: center;
    font-size: 0.9em;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info a.btn,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info a.btn {
    display: none;
  }
  #body .grayed-bg-area .carousel .item.active {
    padding: 0px 14px;
    transition: 1s;
  }
  #body .grayed-bg-area .carousel .item .cell {
    width: 145px;
    transition: 1s;
  }
  #body .grayed-bg-area .carousel .carousel-control {
    color: #ff9900;
    margin-top: -1.5em;
    transition: 1s;
  }
  #body .grayed-bg-area .outside-title {
    font-size: 1.2em;
    margin-top: 20px;
    text-align: center;
    transition: 1s;
  }
  #body .grayed-bg-area .title {
    margin: 15px auto;
    padding: 0;
    transition: 1s;
  }
  #body .grayed-bg-area .title .text {
    transition: 1s;
    background: transparent;
    color: #666;
    float: none;
    padding: 10px 15px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    z-index: 2;
    position: static;
  }
  #body .grayed-bg-area .btn-gray-flat {
    display: block;
    float: none;
    transition: 1s;
  }
  #body .brands-row ul li {
    display: block;
    width: 100%;
    margin: 10px auto;
    float: none;
    transition: 1s;
  }
  #body .brands-row ul li a {
    margin: 20px auto;
    text-align: center;
    transition: 1s;
  }
  #body .brands-row ul li a img {
    text-align: center;
  }
  #body .products-layout .page-header {
    margin-top: 55px;
    padding: 9px 0px;
    transition: 1s;
  }
  #body .products-layout .page-header .breadcrumb {
    background: transparent;
    color: #666;
    margin: 0;
    padding: 5px;
  }
  #body .products-layout .page-header .breadcrumb a {
    color: #666;
  }
  #body .products-layout .page-header:before {
    margin-top: 99px;
    transition: 1s;
  }
  #body .products-layout .page-header h3 {
    color: #666;
    font-size: 1.7em;
    transition: 1s;
  }
  #main-footer {
    text-align: center;
  }
  #main-footer #footer-logo {
    display: block;
    float: none;
    margin: 0 auto;
    transition: 1s;
  }
  #main-footer .first-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .first-col:before {
    display: none;
  }
  #main-footer .first-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
    transition: 1s;
  }
  #main-footer .middle-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .middle-col:before {
    display: none;
    transition: 1s;
  }
  #main-footer .middle-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
    transition: 1s;
  }
  #main-footer .middle-col ul {
    overflow: hidden;
  }
  #main-footer .middle-col ul li {
    float: left;
    text-align: left;
    vertical-align: middle;
    overflow: hidden;
    transition: 1s;
  }
  #main-footer .middle-col ul li div:first-child {
    color: #ff9900;
    float: left;
    margin: 0px 10px;
    display: block;
    text-align: left;
    overflow: hidden;
    height: 60px;
    transition: 1s;
  }
  #main-footer .middle-col ul li div:first-child i {
    float: none;
    display: inline;
    margin: 0;
    height: 0;
  }
  #main-footer .middle-col ul li div:last-child {
    text-align: left;
    width: 290px;
    color: #fff;
  }
  #main-footer .last-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .last-col:before {
    display: none;
    transition: 1s;
  }
  #main-footer .last-col ul {
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    transition: 1s;
  }
  #main-footer .last-col ul li {
    margin: 5px 0px;
    float: left;
    width: 155px;
    display: block;
    text-align: center;
    transition: 1s;
  }
  #main-footer .last-col ul li a {
    font-size: 15px;
  }
}
/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  #main-header .container > .navbar-header,
  #main-header .container-fluid > .navbar-header,
  #main-header .container > .navbar-collapse,
  #main-header .container-fluid > .navbar-collapse {
    margin-right: -150px;
    transition: 1s;
  }
  #main-header #main-navigation .navbar-header {
    margin: 0 auto;
    transition: 1s;
  }
  #main-header .slider .carousel-control {
    color: #fff;
    top: 0%;
    bottom: 0%;
    width: 15%;
    z-index: 10;
    line-height: 16em;
  }
  #main-header .slider .item .carousel-caption {
    padding-bottom: 0px;
    bottom: 0%;
  }
  #main-header .slider .item .carousel-caption h4 {
    font-size: 2em;
  }
  #main-header .slider .item .carousel-caption p {
    font-size: 1.2em;
  }
  #body {
    margin: 0 auto;
  }
  #body #index-tabs .nav-tabs {
    margin: 20px auto;
    border: 0px;
    text-align: center;
  }
  #body #index-tabs .tab-content > .active {
    height: 320px;
    overflow: hidden;
    overflow-x: scroll;
  }
  #body #index-tabs .tab-content > .active .futured-products {
    min-width: 1100px;
    padding: 0;
    margin: 0;
  }
  #body #index-tabs .tab-content > .active .futured-products li {
    margin: 0 10px;
    padding: 0;
    width: auto !important;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay {
    background: transparent;
    position: relative;
    display: block;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .search-icon,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .search-icon {
    display: none;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info {
    position: relative;
    background: transparent;
    color: #666;
    text-align: center;
    font-size: 0.9em;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info a.btn,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info a.btn {
    display: none;
  }
  #body .grayed-bg-area .carousel .item.active {
    padding: 0px 14px;
  }
  #body .grayed-bg-area .carousel .item .cell {
    float: left;
    width: 120px;
    border: 1px solid #ccc;
    margin: 40px 0px;
    margin-left: 20px;
  }
  #body .grayed-bg-area .carousel .carousel-control {
    color: #ff9900;
    margin-top: -1.5em;
  }
  #body .grayed-bg-area .outside-title {
    font-size: 1.2em;
    margin-top: 20px;
    text-align: center;
  }
  #body .grayed-bg-area .title {
    margin: 15px auto;
    padding: 0;
  }
  #body .grayed-bg-area .title .text {
    background: transparent;
    color: #666;
    float: none;
    padding: 10px 15px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    z-index: 2;
    position: static;
  }
  #body .grayed-bg-area .btn-gray-flat {
    display: block;
    float: none;
  }
  #body .brands-row ul {
    display: block;
  }
  #body .brands-row ul li {
    display: block;
    width: 100%;
    margin: 10px auto;
    float: none;
    transition: 1s;
  }
  #body .brands-row ul li a {
    margin: 20px auto;
    text-align: center;
    transition: 1s;
  }
  #body .brands-row ul li a img {
    text-align: center;
  }
  #body .products-layout .page-header {
    margin-top: 55px;
    padding: 9px 0px;
    transition: 1s;
  }
  #body .products-layout .page-header .breadcrumb {
    background: transparent;
    color: #666;
    margin: 0;
    padding: 0;
  }
  #body .products-layout .page-header .breadcrumb a {
    color: #333;
  }
  #body .products-layout .page-header:before {
    margin-top: 92px;
  }
  #body .products-layout .page-header h3 {
    color: #666;
    font-size: 1.3em;
  }
  #main-footer {
    text-align: center;
  }
  #main-footer #footer-logo {
    display: block;
    float: none;
    margin: 0 auto;
  }
  #main-footer .first-col {
    float: none;
    display: block;
    width: 100%;
  }
  #main-footer .first-col:before {
    display: none;
  }
  #main-footer .first-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
  }
  #main-footer .middle-col {
    float: none;
    display: block;
    width: 100%;
  }
  #main-footer .middle-col:before {
    display: none;
  }
  #main-footer .middle-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
  }
  #main-footer .middle-col ul li i {
    color: #ff9900;
    float: none;
    margin: 10px;
    display: block;
    text-align: center;
  }
  #main-footer .last-col {
    float: none;
    display: block;
    width: 100%;
  }
  #main-footer .last-col:before {
    display: none;
  }
  #main-footer .last-col ul {
    margin: 0 auto;
  }
  #main-footer .last-col ul li {
    margin: 5px 0px;
  }
  #main-footer .last-col ul li a {
    font-size: 15px;
  }
}
/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  #main-header #fixed-nav {
    position: relative;
  }
  #main-header #main-navigation .navbar-header {
    margin: 0 auto;
    transition: 1s;
  }
  #main-header .slider .carousel-control {
    color: #fff;
    top: 0%;
    bottom: 0%;
    width: 32%;
    z-index: 10;
    line-height: 250px;
    transition: 1s;
  }
  #main-header .slider .item .carousel-caption {
    padding-bottom: 0px;
    bottom: 0%;
    transition: 1s;
  }
  #main-header .slider .item .carousel-caption h4 {
    font-size: 2em;
    transition: 1s;
  }
  #main-header .slider .item .carousel-caption p {
    font-size: 1.2em;
    transition: 1s;
  }
  #body {
    margin: 0 auto;
  }
  #body #index-tabs .nav-tabs {
    margin: 20px auto;
    border: 0px;
    text-align: center;
    transition: 1s;
  }
  #body #index-tabs .nav-tabs li {
    display: inline;
    float: none;
    text-align: center;
    transition: 1s;
  }
  #body #index-tabs .nav-tabs li.active a {
    display: inline;
    float: none;
    color: #2e3192;
    transition: 1s;
  }
  #body #index-tabs .nav-tabs li a {
    margin: 0;
    display: inline;
    float: none;
    background: transparent;
    padding: 0px 5px;
    font-size: 0.9em;
    color: #666;
    text-align: center;
    transition: 1s;
  }
  #body #index-tabs .nav-tabs li:after {
    content: " ";
    width: auto;
    height: auto;
    border: none;
    border-right: 1px solid #ccc;
    position: static;
    left: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
  #body #index-tabs .nav-tabs li:last-child:after {
    display: none;
    transition: 1s;
  }
  #body #index-tabs .tab-content > .active {
    height: 320px;
    overflow: hidden;
    overflow-x: scroll;
  }
  #body #index-tabs .tab-content > .active .futured-products {
    min-width: 1100px;
    padding: 0;
    margin: 0;
  }
  #body #index-tabs .tab-content > .active .futured-products li {
    margin: 0 10px;
    padding: 0;
    width: auto !important;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay {
    background: transparent;
    position: relative;
    display: block;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .search-icon,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .search-icon {
    display: none;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info {
    position: relative;
    background: transparent;
    color: #666;
    text-align: center;
    font-size: 0.9em;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info a.btn,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info a.btn {
    display: none;
  }
  #body .grayed-bg-area .title {
    margin: 15px auto;
    padding: 0;
    transition: 1s;
  }
  #body .grayed-bg-area .title .text {
    background: transparent;
    color: #666;
    float: none;
    padding: 10px 15px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    z-index: 2;
    position: static;
    transition: 1s;
  }
  #body .grayed-bg-area .btn-gray-flat {
    display: block;
    float: none;
    transition: 1s;
  }
  #body .brands-row ul {
    display: block;
  }
  #body .brands-row ul li {
    display: block;
    width: 100%;
    float: none;
    text-align: center;
    transition: 1s;
  }
  #body .brands-row ul li a {
    margin: 20px auto;
    text-align: center;
    transition: 1s;
  }
  #body .brands-row ul li a img {
    text-align: center;
  }
  #body .products-layout .page-header {
    margin-top: 0px;
    padding: 10px 0px;
    background: #ccc;
    transition: 1s;
  }
  #body .products-layout .page-header .breadcrumb {
    background: transparent;
    color: #666;
    margin: 0;
  }
  #body .products-layout .page-header .breadcrumb a {
    color: #333;
  }
  #body .products-layout .page-header:before {
    display: none;
    transition: 1s;
  }
  #body .products-layout .page-header h3 {
    color: #666;
    font-size: 1.3em;
    transition: 1s;
  }
  #body .products-layout .products-row .item {
    margin: 0;
    margin-bottom: 10px;
    border: 0px;
    border-bottom: 0px;
    display: block;
    transition: 1s;
  }
  #body .products-layout .products-row .item:before {
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }
  #body .products-layout .products-row .item:after {
    content: " ";
    display: block;
    height: 1px;
    clear: both;
    wdith: 200%;
    border-bottom: 1px solid red;
    position: absolute;
  }
  #body .products-layout .products-row .item figure {
    float: left;
    z-index: 2;
    width: 50%;
    transition: 1s;
  }
  #body .products-layout .products-row .item figure img {
    width: 100%;
  }
  #body .products-layout .products-row .item h4 {
    display: none;
  }
  #body .products-layout .products-row .item .overlay {
    display: block;
    position: absolute;
    background: transparent;
    border: 0px;
    z-index: 1;
  }
  #body .products-layout .products-row .item .overlay .info {
    top: 0%;
    overflow: hidden;
    margin-top: 57px;
    left: 45%;
    min-height: 50px;
    height: 50px;
    background: transparent;
    border: 0px;
    color: #666;
    border-bottom: 0px;
  }
  #body .products-layout .products-row .item .overlay .info h4 {
    color: #666;
    display: block;
    background: transparent;
    border: 0px;
  }
  #body .products-layout .products-row .item .overlay .info .btn {
    color: #666;
    right: 3%;
    line-height: 40px;
    background: transparent;
  }
  #body .products-layout .products-row .item .overlay .search-icon {
    display: none;
  }
  #main-footer {
    text-align: center;
    transition: 1s;
  }
  #main-footer #footer-logo {
    display: block;
    float: none;
    margin: 0 auto;
    transition: 1s;
  }
  #main-footer .first-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .first-col:before {
    display: none;
    transition: 1s;
  }
  #main-footer .first-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
    transition: 1s;
  }
  #main-footer .middle-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .middle-col:before {
    display: none;
    transition: 1s;
  }
  #main-footer .middle-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
    transition: 1s;
  }
  #main-footer .middle-col ul li i {
    color: #ff9900;
    float: none;
    margin: 10px;
    display: block;
    text-align: center;
    transition: 1s;
  }
  #main-footer .last-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .last-col:before {
    display: none;
  }
  #main-footer .last-col ul {
    margin: 0 auto;
  }
  #main-footer .last-col ul li {
    margin: 5px 0px;
    transition: 1s;
  }
  #main-footer .last-col ul li a {
    font-size: 15px;
    transition: 1s;
  }
}
/* Portrait phones and smaller */
@media (max-width: 480px) {
  #main-header #fixed-nav {
    position: relative;
  }
  #main-header #main-navigation .navbar-header {
    margin: 0 auto;
    transition: 1s;
  }
  #main-header #main-navigation #categories-dropdown .cell ul li a {
    display: none;
  }
  #main-header #main-navigation #categories-dropdown .cell ul li .title {
    display: block !important;
  }
  #main-header .slider .carousel-control {
    color: #fff;
    top: -2%;
    bottom: 1%;
    width: 32%;
    z-index: 10;
    line-height: 136px;
    transition: 1s;
  }
  #main-header .slider .item .carousel-caption {
    padding-bottom: 0px;
    bottom: 0%;
    transition: 1s;
  }
  #main-header .slider .item .carousel-caption h4 {
    font-size: 0.9em;
    transition: 1s;
    padding: 0.3em;
  }
  #main-header .slider .item .carousel-caption p {
    font-size: 0.7em;
    padding: 0.3em;
    margin-top: 0px;
    transition: 1s;
  }
  #body {
    margin: 0 auto;
  }
  #body #index-tabs .nav-tabs {
    margin: 20px auto;
    border: 0px;
    text-align: center;
  }
  #body #index-tabs .nav-tabs li {
    display: inline;
    float: none;
    text-align: center;
  }
  #body #index-tabs .nav-tabs li.active a {
    display: inline;
    float: none;
    color: #2e3192;
  }
  #body #index-tabs .nav-tabs li a {
    margin: 0;
    display: inline;
    float: none;
    background: transparent;
    padding: 0px 5px;
    font-size: 0.9em;
    color: #666;
    text-align: center;
  }
  #body #index-tabs .nav-tabs li:after {
    content: " ";
    width: auto;
    height: auto;
    border: none;
    border-right: 1px solid #ccc;
    position: static;
    left: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
  #body #index-tabs .nav-tabs li:last-child:after {
    display: none;
  }
  #body #index-tabs .tab-content > .active {
    height: 320px;
    overflow: hidden;
    overflow-x: scroll;
  }
  #body #index-tabs .tab-content > .active .futured-products {
    min-width: 1100px;
    padding: 0;
    margin: 0;
  }
  #body #index-tabs .tab-content > .active .futured-products li {
    margin: 0 10px;
    padding: 0;
    width: auto !important;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay {
    background: transparent;
    position: relative;
    display: block;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .search-icon,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .search-icon {
    display: none;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info {
    position: relative;
    background: transparent;
    color: #666;
    text-align: center;
    font-size: 0.9em;
  }
  #body #index-tabs .tab-content > .active .futured-products li .item .overlay .info a.btn,
  #body #index-tabs .tab-content > .active .futured-products li .item:hover .overlay .info a.btn {
    display: none;
  }
  #body .grayed-bg-area .title {
    margin: 15px auto;
    padding: 0;
    transition: 1s;
  }
  #body .grayed-bg-area .title .text {
    background: transparent;
    color: #666;
    float: none;
    padding: 10px 15px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    z-index: 2;
    position: static;
    transition: 1s;
  }
  #body .grayed-bg-area .btn-gray-flat {
    display: block;
    float: none;
    transition: 1s;
  }
  #body .brands-row ul li {
    display: block;
    width: 100%;
    margin: 10px auto;
    float: none;
    transition: 1s;
  }
  #body .products-layout .page-header {
    margin-top: 0px;
    padding: 10px 0px;
    background: #ccc;
    transition: 1s;
  }
  #body .products-layout .page-header .breadcrumb {
    background: transparent;
    color: #666;
    margin: 0;
  }
  #body .products-layout .page-header .breadcrumb a {
    color: #333;
  }
  #body .products-layout .page-header:before {
    display: none;
    transition: 1s;
  }
  #body .products-layout .page-header h3 {
    color: #666;
    font-size: 1.3em;
    transition: 1s;
  }
  #body .products-layout .products-row .item {
    margin: 0;
    margin-bottom: 10px;
    border: 0px;
    transition: 1s;
    display: block;
  }
  #body .products-layout .products-row .item:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    wdith: 100%;
  }
  #body .products-layout .products-row .item figure {
    float: left;
    z-index: 2;
    width: 80px;
  }
  #body .products-layout .products-row .item figure img {
    width: 100%;
  }
  #body .products-layout .products-row .item h4 {
    display: none;
  }
  #body .products-layout .products-row .item .overlay {
    display: block;
    position: absolute;
    background: transparent;
    border: 0px;
    z-index: 1;
  }
  #body .products-layout .products-row .item .overlay .info {
    top: 0%;
    overflow: hidden;
    margin-top: 10px;
    left: 33%;
    min-height: 60px;
    background: transparent;
    border: 0px;
    color: #666;
    border-bottom: 1px solid #ccc;
  }
  #body .products-layout .products-row .item .overlay .info h4 {
    color: #666;
    display: block;
    background: transparent;
    border: 0px;
  }
  #body .products-layout .products-row .item .overlay .info .btn {
    color: #666;
    background: transparent;
  }
  #body .products-layout .products-row .item .overlay .search-icon {
    display: none;
  }
  #main-footer {
    text-align: center;
  }
  #main-footer #footer-logo {
    display: block;
    float: none;
    margin: 0 auto;
    transition: 1s;
  }
  #main-footer .first-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .first-col:before {
    display: none;
    transition: 1s;
  }
  #main-footer .first-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
    transition: 1s;
  }
  #main-footer .middle-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .middle-col:before {
    display: none;
  }
  #main-footer .middle-col:after {
    content: " ";
    display: block;
    height: 0;
    border-bottom: 1px solid #ccc;
    margin: 10px auto;
    transition: 1s;
  }
  #main-footer .middle-col ul li i {
    color: #ff9900;
    float: none;
    margin: 10px;
    display: block;
    text-align: center;
    transition: 1s;
  }
  #main-footer .last-col {
    float: none;
    display: block;
    width: 100%;
    transition: 1s;
  }
  #main-footer .last-col:before {
    display: none;
    transition: 1s;
  }
  #main-footer .last-col ul {
    margin: 0 auto;
    transition: 1s;
  }
  #main-footer .last-col ul li {
    margin: 5px 0px;
    transition: 1s;
  }
  #main-footer .last-col ul li a {
    font-size: 15px;
    transition: 1s;
  }
}
@media (max-width: 1200px) {
  #body .products-layout .page-header:before {
    background: none;
  }
}

/*# sourceMappingURL=style.css.map */
